import './App.css';
import React from 'react';
import{Link, BrowserRouter, Route, Routes} from 'react-router-dom'

import BookPage from './BookPages';
import PicturePage from './PicturePages';
import HomePage from './HomePage';
import AddArticle from './AddArticle';
import SearchPage from './SearchPage';
import LoginPage from './LoginPage';
import DocPage from './DocPage';
import GitPage from './GitPage';

import { StdComponentsTitleSubt, StdComponentsTitle } from './StdComponents';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Container } from 'react-bootstrap';

function App() {

  return (

      <Container>
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="/home">Home</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/book" to>Libros</Nav.Link>
                <Nav.Link href="/picture" to>Cuadros</Nav.Link>
                <Nav.Link href="/search" to>Buscar articulo</Nav.Link>
                <Nav.Link href="/add" to>Agregar articulo</Nav.Link>
                <Nav.Link href="/login" to>Login</Nav.Link>
                <Nav.Link href="/doc" to>Documentacion</Nav.Link>
                <Nav.Link href="/git" to>Repositorio Github</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        
        <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage/>}></Route>
          <Route path='/home' element={<HomePage/>}></Route>
          <Route path='/book' element={<BookPage/>}></Route>
          <Route path='/picture' element={<PicturePage/>}></Route>
          <Route path='/search' element={<SearchPage/>}></Route>
          <Route path='/add' element={<AddArticle/>}></Route>     
          <Route path='/login' element={<LoginPage/>}></Route>
          <Route path='/doc' element={<DocPage/>}></Route>
          <Route path='/git' element={<GitPage/>}></Route>
        </Routes>
      </BrowserRouter>
      </Container>

      

  );
}

export default App;
