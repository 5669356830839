import './App.css';

import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';

import { StdComponentsTitle } from './StdComponents';

import { useEffect, useState } from "react"

function AddArticle() {

  const [users, setUsers] = useState([])
  const [newName, setNewName] = useState("")
  const [newType, setNewType] = useState("")
  const [newAuthor, setNewAuthor] = useState("")
  const [newQuantity, setNewQuantity] = useState("")
  const [newPrice, setNewPrice] = useState("")
  const [newImg, setNewImg] = useState("")

  ///////////////////////////////////////////////////////////////
  //////////                  CREATE                   //////////
  ///////////////////////////////////////////////////////////////

  const addUser_old = () => {
    const id = "1"
    const name = newName.trim()
    const type = newType.trim()
    const author = newAuthor.trim()
    const quantity = newQuantity.trim()
    const price = newPrice.trim()
    const img = newImg.trim()
    const deshab = false

    if (name && type && author && quantity && price && img) {
      //fetch("https://hotinger_p2-1-e0269260.deta.app/productsdb/", {
      fetch("http://127.0.0.1:8000/productsdb/", {
        method: "POST",
        body: JSON.stringify({
          id,
          name,
          type,
          author,
          quantity,
          price,
          img,
          deshab,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then(response => response.json())
        .then(data => {
          setUsers([...users, data])
          setNewName("")
          setNewType("")
          setNewAuthor("")
          setNewQuantity("")
          setNewPrice("")
          setNewImg("")
        })
        .then(() => {
          alert("Articulo creado con exito")
          window.location.reload(false);
        })
        
    }
  }

  async function addUser (){

    const id = "1"
    const name = newName.trim()
    const type = newType.trim()
    const author = newAuthor.trim()
    const quantity = newQuantity.trim()
    const price = newPrice.trim()
    const img = newImg.trim()
    const deshab = false

    let response

    if (name && type && author && quantity && price && img) {
      response = await fetch("https://hotinger_p2-1-e0269260.deta.app/productsdb/", {
      //response = await fetch("http://127.0.0.1:8000/productsdb/", {  
        method: "POST",
        body: JSON.stringify({
          id,
          name,
          type,
          author,
          quantity,
          price,
          img,
          deshab,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          'Authorization': 'Bearer '+ localStorage.getItem('jwt_token')
        },
      })

      let userData = await response.json()
      if (response.ok){
        console.log(localStorage.getItem('jwt_token'))
        window.location.reload(false)
        alert(response.statusText)    
         
      }
      else{
        console.log(localStorage.getItem('jwt_token'))
        window.location.reload(false)
        alert("Error: " + response.statusText)  
        
      } 
    }
  }

  ///////////////////////////////////////////////////////////////
  //////////                  RETURN                   //////////
  ///////////////////////////////////////////////////////////////

  return (

    <Container>
      <Row>
        <Col xs={1}></Col>

        <Col xs={12}>

          <StdComponentsTitle value="Agregar articulo"></StdComponentsTitle>

          <Row>
            
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Titulo</Form.Label>
                  <Form.Control
                    name='name'
                    required
                    type="text"
                    onChange={e => setNewName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form>
                  {['radio'].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check // prettier-ignore
                        type={type}
                        id="Libro"
                        label="Libro"
                        name="type"
                        value="Libro"
                        onChange={e => setNewType(e.target.value)}
                      />

                      <Form.Check
                        type={type}
                        label="Cuadro"
                        id="Cuadro"
                        name="type"
                        value="Cuadro"
                        onChange={e => setNewType(e.target.value)}
                      />
                    </div>
                  ))}
                </Form>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Autor</Form.Label>
                  <Form.Control
                    name='author'
                    required
                    type="text"
                    onChange={e => setNewAuthor(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Cantidad</Form.Label>
                  <Form.Control
                    name='quantity'
                    required
                    type="number"
                    onChange={e => setNewQuantity(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Precio</Form.Label>
                  <Form.Control
                    name='price'
                    required
                    type="number"
                    onChange={e => setNewPrice(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Link de la imagen</Form.Label>
                  <Form.Control
                    name='img'
                    required
                    type="text"
                    onChange={e => setNewImg(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Button onClick={addUser}>Agregar articulo</Button>
            </Form>

          </Row>

        </Col>

        <Col xs={1}></Col>
      </Row>
    </Container>

  );
}

AddArticle.defaultProps = {
  method: 'post'
};

export default AddArticle;
