import './App.css';

import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { StdComponentsTitle } from './StdComponents';

import { useEffect, useState } from "react"


function SearchPage() {

  const [users, setUsers] = useState([])

  const [newSearchParam, setNewSearchParam] = useState("")
  const [newName, setNewName] = useState("")


  ///////////////////////////////////////////////////////////////
  //////////                  SEARCH                   //////////
  ///////////////////////////////////////////////////////////////

  const user_empty = [
    {

    }
  ]

  const searchUserB = () => {
    fetch("https://hotinger_p2-1-e0269260.deta.app/productsdb/?"+newSearchParam+"="+newName)
      .then(response => response.json())
      .then(json => setUsers(json))
  }

  const searchProduct = () => {

    var statusResponse = 0

    fetch("https://hotinger_p2-1-e0269260.deta.app/productsdb/?"+newSearchParam+"="+newName)
    .then(response => {
      if (!response.ok) {
          return Promise.reject(response);
      }
      //statusResponse = response.status
      //console.log(statusResponse)
      return response.json();
    })
    .then(data => {
        //console.log("Success");
        //console.log(data);
        setUsers(data) 
    })
    .catch(error => {
        if (typeof error.json === "function") {
            error.json().then(jsonError => {
                console.log("Json error from API");
                console.log(jsonError);
            }).catch(genericError => {
                console.log("Generic error from API");
                console.log(error.statusText);
            });
        } else {
            console.log("Fetch error");
            setUsers(user_empty)                            //  Borro los valores de la tabla
            console.log(error);
        }
    });
  }

  ///////////////////////////////////////////////////////////////
  //////////                  RETURN                   //////////
  ///////////////////////////////////////////////////////////////

  return (

    <Container>
      <Row>
        <Col xs={1}></Col>

        <Col xs={12}>

          <StdComponentsTitle value="Busqueda de articulos por nombre o autor"></StdComponentsTitle>

          <Row>

            <Form>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Parametro de busqueda</Form.Label>
                  <Form.Control
                    name='name'
                    required
                    type="text"
                    onChange={e => setNewName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form>
                  {['radio'].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check // prettier-ignore
                        type={type}
                        id="Titulo"
                        label="Titulo"
                        name="search"
                        value="name"
                        onChange={e => setNewSearchParam(e.target.value)}
                      />

                      <Form.Check
                        type={type}
                        label="Autor"
                        id="Autor"
                        name="search"
                        value="author"
                        onChange={e => setNewSearchParam(e.target.value)}
                      />
                    </div>
                  ))}
                </Form>
                </Form.Group>
              <Button onClick={searchProduct}>Buscar</Button>
            </Form>

            <StdComponentsTitle value="Resultados"></StdComponentsTitle>
        
            <Table striped bordered hover>

              <thead>
                <tr>
                  <th>#</th>
                  <th>Titulo</th>
                  <th>Autor</th>
                  <th>Tipo</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                </tr>
              </thead>
              <tbody>
              {users.map(user => (
                <tr>
                  <td>#</td>
                  <td>{user.name}</td>
                  <td>{user.author}</td>
                  <td>{user.type}</td>
                  <td>{user.quantity}</td>
                  <td>{user.price}</td>
                </tr>
              ))}
              </tbody>
            </Table>

          </Row>

        </Col>

        <Col xs={1}></Col>
      </Row>
    </Container>
  );
}

export default SearchPage;
