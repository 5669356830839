import './App.css';

import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';

import { StdComponentsTitle } from './StdComponents';

import { useEffect, useState } from "react"

function LoginPage() {

  const [users, setUsers] = useState([])
  const [newUsername, setNewUsername] = useState("")
  const [newPassword, setNewPassword] = useState("")


  ///////////////////////////////////////////////////////////////
  //////////                  CREATE                   //////////
  ///////////////////////////////////////////////////////////////

  async function login () {

    const username = newUsername.trim()
    const password = newPassword.trim()
    //const username = "admin"
    //const password = "123456"

    let bodyFormData = new FormData();
    bodyFormData.append("username", username);
    bodyFormData.append("password", password);
    //bodyFormData.append("username", username);
    //bodyFormData.append("password", password);
    let response

    if (username && password) {
      // https://www.appsloveworld.com/python/1361/fastapi-react-native-frontend-giving-422-error-during-auth-flow
      //fetch("https://hotinger_p2-1-e0269260.deta.app/productsdb/", {
        //response = await fetch("http://127.0.0.1:8000/login", {
        response = await fetch("https://hotinger_p2-1-e0269260.deta.app/login", {
        method: "POST",
        body: bodyFormData,
      })
      let userData = await response.json()
      if (response.ok){
        localStorage.setItem('jwt_token',userData.access_token)
        //console.log(localStorage.getItem('jwt_token')
        window.location.reload(false);
        alert("Loggin exitoso")     
      }
      else{
        window.location.reload(false);
        alert("Falla de login")  
      }
    }
  }

  ///////////////////////////////////////////////////////////////
  //////////                  RETURN                   //////////
  ///////////////////////////////////////////////////////////////

  return (

    <Container>
      <Row>
        <Col xs={4}></Col>

        <Col xs={4}>

          <StdComponentsTitle value="Login usuario"></StdComponentsTitle>

          <Row>
            
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nombre de usuario</Form.Label>
              <Form.Control 
                required
                type="text" 
                name='username' 
                placeholder="Usuario habilitado: admin" 
                onChange={e => setNewUsername(e.target.value)}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                required
                type="password" 
                name='password' 
                placeholder="Password: 123456"
                onChange={e => setNewPassword(e.target.value)}/>
            </Form.Group>
            <Button onClick={login}>Login</Button>
          </Form>

          </Row>

        </Col>

        <Col xs={4}></Col>
      </Row>
    </Container>

  );
}

LoginPage.defaultProps = {
  method: 'post'
};

export default LoginPage;

