import './App.css';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import { useEffect, useState } from "react"

import {StdComponentsTitle } from './StdComponents';

function BookPage() {

  const [users, setUsers] = useState([])

  useEffect(() => {
    fetch("https://hotinger_p2-1-e0269260.deta.app/productsdb/")
      .then(response => response.json())
      .then(json => setUsers(json))
  }, [])

  //////////////////////////////////////////////////////////////
  //////////                  UPDATE                   //////////
  ///////////////////////////////////////////////////////////////

  const oldUpdateUser = id => {
    const user = users.find(user => user.id === id)

    try{
      fetch(`https://hotinger_p2-1-e0269260.deta.app/productsdb/`, {
        method: "PUT",
        body: JSON.stringify(user),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then(response => response.json())
        .then(() => {
          alert("Valores modificados con exito")
        })
    
    }

    catch(e){
      console.log(e)
    }

  }

  async function updateUser (id){

    const user = users.find(user => user.id === id)
    //let response = await fetch(`http://127.0.0.1:8000/productsdb/`, {
    let response = await fetch(`https://hotinger_p2-1-e0269260.deta.app/productsdb/`, {
      method: "PUT",
      body: JSON.stringify(user),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        'Authorization': 'Bearer '+ localStorage.getItem('jwt_token')
      },
    })

    let userData = await response.json()

    if (response.ok){
      window.location.reload(false)
      alert(response.statusText)    
        
    }
    else{
      window.location.reload(false)
      alert("Error: " + response.statusText)     
    } 
  }

  ///////////////////////////////////////////////////////////////
  //////////                  DELETE                   //////////
  ///////////////////////////////////////////////////////////////

  const odlDeleteUser = id => {
    fetch(`https://hotinger_p2-1-e0269260.deta.app/productsdb/${id}`, {
      method: "DELETE",
    })
      .then(response => response.json())
      .then(() => {
        setUsers(values => {
          return values.filter(item => item.id !== id)
        })
      })
      .then(() => {
        alert("Articulo eliminado con exito")
      })
    }

  async function deleteUser (id){
    let response = await fetch(`https://hotinger_p2-1-e0269260.deta.app/productsdb/${id}`, {
    //let response = await fetch(`http://127.0.0.1:8000/productsdb/${id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        'Authorization': 'Bearer '+ localStorage.getItem('jwt_token')
      },
    })

    let userData = await response.json()

    if (response.ok){
      setUsers(values => {
        return values.filter(item => item.id !== id)
      })
      window.location.reload(false)
      alert(response.statusText)    
       
    }
    else{
      window.location.reload(false)
      alert("Error: " + response.statusText)     
    } 
  }

  const onChangeHandler = (id, key, value) => {
    setUsers(values => {
      return values.map(item =>
        item.id === id ? { ...item, [key]: value } : item
      )
    })
  }

  ///////////////////////////////////////////////////////////////
  //////////                  RETURN                   //////////
  ///////////////////////////////////////////////////////////////

  return (

    <Container>
      <Row>
        <Col xs={1}></Col>

        <Col xs={12}>

            <StdComponentsTitle value="Libros"></StdComponentsTitle>

            <Row xs={2} md={3} className="g-4">
                {users.map(user => (
                user.type === 'Libro' ?
            
                    <Col key={user.id}>
                    <Card bg="light" style={{ width: '18rem' }}>
                        <Card.Img variant="top"
                        src={user.img}
                        alt='...'
                        position='top'
                        />
                        <Card.Body>
                        <Card.Title>{/*user.name*/}</Card.Title>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Titulo</InputGroup.Text>
                            <Form.Control
                            disabled={user.deshab}
                            placeholder={user.name}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={e => onChangeHandler(user.id, "name", e.target.value)}
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Autor</InputGroup.Text>
                            <Form.Control
                            disabled={user.deshab}
                            placeholder={user.author}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={e => onChangeHandler(user.id, "author", e.target.value)}
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Cantidad</InputGroup.Text>
                            <Form.Control
                            disabled={user.deshab}
                            placeholder={user.quantity}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={e => onChangeHandler(user.id, "quantity", e.target.value)}
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Precio</InputGroup.Text>
                            <Form.Control
                            disabled={user.deshab}
                            placeholder={user.price}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={e => onChangeHandler(user.id, "price", e.target.value)}
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Link imagen</InputGroup.Text>
                            <Form.Control
                            disabled={user.deshab}
                            placeholder={user.img}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={e => onChangeHandler(user.id, "img", e.target.value)}
                            />
                        </InputGroup>

                        </Card.Body>
                        <Card.Footer>
                        <ButtonGroup  aria-label='Basic example'>
                            <Button variant="success"intent="primary" disabled={user.deshab} onClick={() => updateUser(user.id)}>Modificar</Button>
                            <Button variant='danger' intent="danger" disabled={user.deshab} onClick={() => deleteUser(user.id)}>Borrar</Button>
                        </ButtonGroup>
                        </Card.Footer>
                    </Card>
                    </Col>
            : null
                ))}
            </Row>
        </Col>

        <Col xs={1}></Col>
      </Row>
    </Container>

  );
}

export default BookPage;
