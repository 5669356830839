import './App.css';

import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';

import { StdComponentsTitle } from './StdComponents';

import { BrowserRouter } from 'react-router-dom';

function GitPage() {

  function doc () {
    window.open("https://github.com/diegohcalleja/Portfolio-Python-A") 
  }

  ///////////////////////////////////////////////////////////////
  //////////                  RETURN                   //////////
  ///////////////////////////////////////////////////////////////

  return (

    <Container>
      <Row>
        <Col xs={4}></Col>
            <Col xs={4}>
                <StdComponentsTitle value="Repositorio de GitHub"></StdComponentsTitle>
                <Row>
                    <Form>
                        <div class="text-center">
                            <Button onClick={doc}>Abrir repositorio</Button>
                        </div>
                    </Form>
                </Row>
            </Col>
        <Col xs={4}></Col>
      </Row>
    </Container>
  );
}

export default GitPage;

