import React from 'react';

export function StdComponentsTitleSubt({title,subtitle}){
    return(
        <header>
            <div className='p-5 text-center'>
                <h1 className='mb-3'>{title}</h1>
                <h4 className='mb-3'>{subtitle}</h4>
            </div>
      </header>
    )
}

export function StdComponentsTitle({value}){
    return(
        <div className='p-5 text-center'>
            <h1 className='mb-3'>{value}</h1>
        </div>
    )
}

export function StdComponentsSubt({subtitle}){
    return(

        <div className='p-5 text-center'>
            <h4 className='mb-3'>{subtitle}</h4>
        </div>

    )
}
