import './App.css';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import { StdComponentsTitleSubt, StdComponentsSubt } from './StdComponents';

import { useEffect, useState } from "react"

function HomePage() {
  const [showBasic, setShowBasic] = useState(false);

  const [users, setUsers] = useState([])
  const [newName, setNewName] = useState("")
  const [newType, setNewType] = useState("")
  const [newAuthor, setNewAuthor] = useState("")
  const [newQuantity, setNewQuantity] = useState("")
  const [newPrice, setNewPrice] = useState("")
  const [newImg, setNewImg] = useState("")

  useEffect(() => {
    fetch("https://hotinger_p2-1-e0269260.deta.app/productsdb/")
      .then(response => response.json())
      .then(json => setUsers(json))
  }, [])

  const [formValue, setFormValue] = useState({
    fname: '',
    lname: '',
    email: '',
    city: '',
    state: '',
    zip: '',
  });

  ///////////////////////////////////////////////////////////////
  //////////                  CREATE                   //////////
  ///////////////////////////////////////////////////////////////

  const addUser = () => {
    const id = "1"
    const name = newName.trim()
    const type = newType.trim()
    const author = newAuthor.trim()
    const quantity = newQuantity.trim()
    const price = newPrice.trim()
    const img = newImg.trim()

    if (name && type && author && quantity && price && img) {
      fetch("https://hotinger_p2-1-e0269260.deta.app/productsdb/", {
        method: "POST",
        body: JSON.stringify({
          id,
          name,
          type,
          author,
          quantity,
          price,
          img,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then(response => response.json())
        .then(data => {
          setUsers([...users, data])
          setNewName("")
          setNewType("")
          setNewAuthor("")
          setNewQuantity("")
          setNewPrice("")
          setNewImg("")
        })
    }
  }

  ///////////////////////////////////////////////////////////////
  //////////                  UPDATE                   //////////
  ///////////////////////////////////////////////////////////////

  const updateUser = id => {
    const user = users.find(user => user.id === id)

    try{
      fetch(`https://hotinger_p2-1-e0269260.deta.app/productsdb/`, {
        method: "PUT",
        body: JSON.stringify(user),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then(response => response.json())
        .then(() => {
          alert("Valores modificados con exito")
        })
    
    }

    catch(e){
      console.log(e)
    }

  }

  ///////////////////////////////////////////////////////////////
  //////////                  DELETE                   //////////
  ///////////////////////////////////////////////////////////////

  const deleteUser = id => {
    fetch(`https://hotinger_p2-1-e0269260.deta.app/productsdb/${id}`, {
      method: "DELETE",
    })
      .then(response => response.json())
      .then(() => {
        setUsers(values => {
          return values.filter(item => item.id !== id)
        })
      })
      .then(() => {
        alert("Valores modificados con exito")
      })
  }

  const onChangeHandler = (id, key, value) => {
    setUsers(values => {
      return values.map(item =>
        item.id === id ? { ...item, [key]: value } : item
      )
    })
  }

  ///////////////////////////////////////////////////////////////
  //////////                  RETURN                   //////////
  ///////////////////////////////////////////////////////////////

  return (

    <Container>
      <Row>
        <Col xs={1}></Col>

        <Col xs={12}>

          <StdComponentsTitleSubt title="React, FastApi y MongoDB" subtitle="Aplicacion de React, con la cual se puede 
          realizar un CRUD a una BD de MongoDB, a traves de una API creada con FastAPI. Los articulos de muestra no 
          pueden ser modificados."></StdComponentsTitleSubt>

          <StdComponentsSubt subtitle="La cantidad maxima de articulos es de 20, por lo que se permite realizar un CRUD 
          de 2 articulos ademas de los que estan por defecto. El login da una tiempo de acceso de 1 minuto."></StdComponentsSubt>

          <StdComponentsSubt subtitle="Repositorio disponible en GitHub"></StdComponentsSubt>

        </Col>

        <Col xs={1}></Col>
      </Row>
    </Container>

  );
}

export default HomePage;
